import styled from 'styled-components';

export const ModalWrapper = styled.div`
display: block;
background: rgba(40, 40, 40, .9);
position: fixed;
top: 0;
right: 0;
bottom: 0;
left: 0;
z-index: 30000000;
overflow: auto;
outline: 0;
.modal-container {
  padding: ${props => (props.isMobile === true ? '10px' : '')}; 
  width: ${props => (props.isMobile === true ? '100%' : '')};
  min-height: ${props => (props.isMobile === true ? '100vh' : '')};
  max-width: ${props => (props.isMobile === true ? 'none' : '700px')};
  margin: ${props => (props.isMobile === true ? '0' : '1.75rem auto')};
  position: relative;
  background: white;
  padding-bottom: 1px;
  .header {
    display: flex;
    justify-content: center;
    flex-direction: ${props => (props.isMobile === true ? 'column-reverse' : 'row')};
    align-items: center;
    .title {
      padding: ${props => (props.isMobile === true ? '' : '24px 24px 20px 24px')};
      padding-bottom: 8px;
      color: ${props => { return props.theme.color.primaryColor }};
      font-size: ${props => (props.isMobile === true ? '18px' : '24px')};
      font-weight: ${props => parseInt(props.theme.font.primaryFont.weight)+300};
      font-style: italic;
      clear: both;
    }
    .close {
      color: ${props => { return props.theme.color.primaryColor }};
      position: absolute;
      top: 15px;
      right: 15px;
    }
    @media (max-width: 767px) {
      .title {
        padding-right: 15px;
        margin-top: 30px;
      }
      .close {
        position: absolute;
        top: 15px;
        right: 15px;
      }
    }
  }

  .container {
    font-size: 14px;
    padding-bottom: 15px;
    padding-top: 10px;
    h2 {
      font-size: 20px;
      font-weight: bold;
    }
    .section {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      p {
        font-size: 14px;
        vertical-align: center;
        margin-right: 15px;
      }
      .buttons {
        display: flex;
        justify-content: space-between;
        .button {
          padding: 10px;
          cursor: pointer;
          background-color: #A3A3A3;
          color: white;
          margin-left: 20px;
        }
        .allow {
          background-color: green;
        }
        .deny {
          background-color: red;
        }
      }
      .mandatory .button {
        cursor: not-allowed;
      }
    }
    @media (max-width: 767px) {
      h2 {
        font-size: 18px;
      }
      .section {
        flex-direction: column;
      }
      .buttons {
        width: 100%;
        .button {
          text-align: center;
          width: 45%;
          margin-left: 0px !important;
        }
      }
    }
  }
}

`;

import React, { useContext } from 'react';
import { ThemeContext, WebappContext, WhiteLabelContext } from "ToolboxUtils/web/context/context";
import PropTypes from 'prop-types';
import Text from 'ToolboxComponents/commons/texts/text/text';
import getCookieGpdr from 'ToolboxUtils/web/cookie/get-cookie-gpdr';
import {COOKIE_UNSET, COOKIE_STATS_DENIED, COOKIE_STATS_ALLOWED} from 'ToolboxParams/cookies';
import { ModalWrapper } from 'ToolboxComponents/webapp/modals/modal-cookie/modal-cookie.styles';
import isMobileView from 'ToolboxUtils/web/is-mobile-view';


const setCookie = id => {
  document.cookie = "chuchoteurs" + window.location.hostname + "Gpdr=" + id + ";expires=Fri, 31 Dec 2100 23:59:59 GMT; path=/";
}

const ModalCookie = props => {
  const theme = useContext(ThemeContext).state;
  const whiteLabel = useContext(WhiteLabelContext).state;
  const [cookieGpdr, setCookieGpdr] = useContext(WebappContext).usePath("cookieGpdr");

  return (
    <ModalWrapper theme={theme} isMobile={isMobileView()} id='signInScreen'>
      <div className='modal-container' id='signInModal'>
        <div className='header'>
          <h1 className='title'>
            {whiteLabel.textualContent.generic.cookies.modalTitle}
          </h1>
          <button onClick={() => props.onClose()} className='close' type='button'>
            <i className='icon adn adn-close' />
          </button>
        </div>
        <div className='container'>
          <p>
            {whiteLabel.textualContent.generic.cookies.modalText}
          </p>
          <hr />
          <h2> {whiteLabel.textualContent.generic.cookies.modalMandatoryTitle} </h2>
            <div className='section'>
              <p>{whiteLabel.textualContent.generic.cookies.modalMandatoryText}</p>
              <div className='buttons mandatory'>
                <div className='button allow'>
                  {whiteLabel.textualContent.generic.cookies.modalAllow}
                </div>
                <div className='button'>
                  {whiteLabel.textualContent.generic.cookies.modalDeny}
                </div>
              </div>
            </div>
          <hr />
          <h2>{whiteLabel.textualContent.generic.cookies.modalStats}</h2>
            <div className='section'>
            <p>{whiteLabel.textualContent.generic.cookies.modalStatsText}</p>
            <div className='buttons'>
                <div 
                  className={'button' + (cookieGpdr === COOKIE_STATS_ALLOWED ? ' allow' : '')} 
                  onClick={() => {setCookie(COOKIE_STATS_ALLOWED); setCookieGpdr(COOKIE_STATS_ALLOWED)}}
                >
                  {whiteLabel.textualContent.generic.cookies.modalAllow}
                </div>
                <div
                  className={'button' + (cookieGpdr === COOKIE_STATS_DENIED ? ' deny' : '')}
                  onClick={() => {setCookie(COOKIE_STATS_DENIED); setCookieGpdr(COOKIE_STATS_DENIED)}}
                >
                  {whiteLabel.textualContent.generic.cookies.modalDeny}
                </div>
            </div>
            </div>
        </div>
      </div>
    </ModalWrapper>
  );
}

ModalCookie.propTypes = {
}

export default ModalCookie;
